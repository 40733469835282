<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="centerDialogVisible"
      width="45%"
      append-to-body
      center
    >
      <el-row
        type="flex"
        :gutter="20"
        style="margin-bottom: 20px"
        v-if="!diolg"
      >
        <!-- <el-col style="line-height: 32px;width: 800px;">
          {{ $t("devtable.ckyg") }}
          <span style="padding: 0 8px; color: red">{{
            authtabdiolgdata.name
          }}</span>
          {{ $t("devtable.qx") }}
        </el-col> -->
      <!--  <el-col>
          <el-input
            size="small"
            :placeholder="$t('devtable.sbxlh')"
            v-model="serial_number"
          ></el-input>
        </el-col> -->
        <el-col>
          <el-button type="primary" size="small" @click="getsatList">{{
            $t("devtable.cx")
          }}</el-button>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="20" style="margin-bottom: 20px;width: 300px;" v-else>
        <!-- <el-col style="line-height: 32px">
          <!-- {{ $t("devtable.cksb") }}
          <span style="padding: 0 8px;color: red;">{{
            authtabdiolgdata.serial_number
          }}</span> -->
          <!-- {{ $t("devtable.mjjzmc") }} -->
        <!-- </el-col> -->
        <el-col>
          <el-input size="small" :placeholder="this.$t('devtable.mjjzmc')" v-model="name"></el-input>
        </el-col>
        <!-- <el-col>
          <el-button type="primary" @click="handserveQuest" size="small">{{
            $t("devtable.cx")
          }}</el-button>
        </el-col> -->
      </el-row>

      <div v-if="!diolg">
        <el-table
          :data="tableData"
          :tableLabel="tableLabel"
          border
          @selection-change="handleSelectionChange1"
          style="width: 100%"
        >
          <!-- 多选框 -->
          <el-table-column type="selection" align="center" width="40">
          </el-table-column>

          <el-table-column
            v-for="item in tableLabel"
            :key="item.prop"
            :label="item.label"
            :width="item.width ? 180 : item.width"
            :align="item.align"
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">
                {{ scope.row[item.prop] }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
          <!-- <Pagination -->
          <!-- @handleCurrentChange="handleCurrentChange" -->
          <!-- ref="pagination" -->
          <!-- @handleSizeChange="handleSizeChange" -->
          <!-- ></Pagination> -->
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="5"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagecount2"
            :pagerCount="5"
          >
          </el-pagination>
        </div>
      </div>

      <div v-else>
        <el-table
		ref="dataTable"
          :data="tableData2"
          :tableLabel="tableLabel2"
          border
          @selection-change="handleSelectionChange2"
          style="width: 100%"
        >
          <!-- 多选框 -->
          <el-table-column type="selection" align="center" width="40">
          </el-table-column>

          <el-table-column
            v-for="item in tableLabel2"
            :key="item.prop"
            :label="item.label"
            :width="item.width ? 180 : item.width"
            :align="item.align"
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">
                {{ scope.row[item.prop] }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
          <!-- <Pagination -->
          <!-- @handleCurrentChange="handleCurrentChange" -->
          <!-- ref="pagination" -->
          <!-- @handleSizeChange="handleSizeChange" -->
          <!-- ></Pagination> -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[50, 100, 200]"
            :page-size="50"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagecount"
            :pagerCount="5"
          >
          </el-pagination>
        </div>
      </div>

      <span slot="footer" class="dialog-footer" v-if="footerState">
        <!-- <el-button type="primary" v-if="!diolg" @click="handelt">{{
          $t("devtable.scxzqx")
        }}</el-button> -->
       <!-- <el-button type="primary" v-else @click="handsheb">{{
          $t("devtable.scxzsbqx")
        }}</el-button> -->
        <el-button type="primary" @click="addControl">{{
          $t("tablename.qd")
        }}</el-button>
      </span>
	  
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/page";
export default {
  components: {
    Pagination,
  },
  props: {
    // authtabdiolgdata:Object,
    diolg: Boolean,
	footerState:{
		type:Boolean,
		default:true
	}
  },
  data() {
    return {
      ryid: [],
	  jzId:'',
	  selectList:[],
	  selectSingle:true,
      authtabdiolgdata: {},
      name: "",
      serial_number: "",
      centerDialogVisible: false,
	  tableLabel:[],
      tableLabel2: [
		  {
		    prop: "id",
		    label: this.$t("devtable.jzid"),
		    align: "center",
		  },
        {
          prop: "name",
          label: this.$t("devtable.azwz"),
          align: "center",
        },
        {
          prop: "serial_number",
          label: this.$t("devtable.lyh"),
          align: "center",
        },
      ],
      tableData2: [],
      shebid: [],
	  renyId:[],
      count: "",
      page_index: 1,
      page_size: 5,
			page_size_big:50,
      UserInfo: "",
      pagecount: 0,
      currentPage: 4,

      pagecount2:0
    };
  },
  mounted() {
    this.UserInfo = JSON.parse(window.localStorage.getItem("user"));
    // this.handequipmentList();
  },
  // beforeDestroy(){
  //     clearInterval(timer)
  // },
  methods: {
	  selectState(){
		  
	  },
    // 人员 列表
    // stindtime(){

    // },
    handleSizeChange2(val) {
  console.log(`1`, val);
  this.page_size = val;
  this.handserveList();
},
handleCurrentChange2(val) {
  console.log(`2`, val);
  this.page_index = val;
  // this.page_size =5
  this.handserveList();
},
	handselecList(id){
		let data = {
		  method: "co.group.device.get",
		  id:id
		};
		this.$serve(data).then((res) => {
		  console.log(res.data)
		  this.jzId = res.data.data.id
		  this.name = res.data.data.name
		  this.ryid = res.data.data.devices.split(',')
		  this.handequipmentList();
		});
	},
    handserveList() {
      let data = {
        method: "co.key.check.person.list",
        page_index: this.page_index,
        page_size: this.page_size,
        serial_number: "",
        manager_id: this.UserInfo.manager_id,
        agent_id: this.UserInfo.agent_id, //JSON.parse(this.userData).agent_id,
        person_id: this.authtabdiolgdata.id,
        dept_id: "",
      };
      this.$serve(data).then((res) => {
        this.count = res.data.count;
        let data = res.data.data;
        data.forEach((el) => {
          if (el.unlock_state == 0) {
            el.unlock_state = this.$t("devtable.j");
          } else {
            el.unlock_state = this.$t("devtable.c");
          }
        });
        this.pagecount2 = res.data.count;
        this.tableData = data;
      });
    },

    handleSizeChange(val) {
      console.log(`1`, val);
      this.page_size = val;
      this.handequipmentList();
    },
	// 门禁机组新增
	addControl(){
		console.log('998');
		let data = {
		  method: "co.group.device.edit",
		  id:this.jzId,
		  name:this.name,
		  devices: this.shebid.toString(),
		  agent_id:this.UserInfo.agent_id
		};
		this.$serve(data).then((res) => {
		  console.log(res)
		  if(res.data.code==-4){
			this.$message({
			  message: this.$t("devtable.qsrzjmc"),
			  type: "error",
			});
		  }else if(res.data.code==0){
			this.$message({
			  message: this.$t("devtable.xgcg"),
			  type: "success",
			});
		  }
		});
	},
    handleCurrentChange(val) {
      console.log(`2`, val);
      this.page_index = val;
      // this.page_size =5
      this.handequipmentList();
    },
	// 未同步设备列表
	handleGetNoSynList(){
		
		let data = {
		  method: "co.device.no.syn.list",
		  page_index: this.page_index,
		  page_size: this.page_size,
		  id:this.authtabdiolgdata.id
		};
		this.$serve(data).then((res) => {
		  // console.log(res)
		  this.pagecount = res.data.count;
		  this.tableData2 = res.data.data;
		});
	},
    // 设备 列表

    handequipmentList() {
		let that = this 
      console.log(`66`, this.authtabdiolgdata);
      let data = {
        method: "co.group.device.select.list",
        page_index: this.page_index,
        page_size: this.page_size_big,
        agent_id: this.UserInfo.agent_id,
      };
      this.$serve(data).then((res) => {
        // console.log(res)
        this.pagecount = res.data.count;
        this.tableData2 = res.data.data;
		this.tableData2.forEach((item, index) => {
			console.log(item);
			// item.select = false
			this.ryid.forEach(item2=>{
				if(item.id==item2){
					// this.$refs.table.toggleRowSelection(item,true)
					// item.select = true
					// this.$refs.dataTable.toggleRowSelection(item, true)
					this.selectList.push(item)
					console.log(this.selectList)
				}
			})
		 })
		 this.$nextTick(()=>{
			 if(this.selectList.length!=0){
			 			 this.selectList.forEach((row) => {
			 				 console.log(this.$refs.dataTable)
			 			   this.$refs.dataTable.toggleRowSelection(row, true)
			 			   this.shebid.push(row.id)
			 			 })
			 			 
			 			 console.log('876',this.shebid)
			 }
		 })
		 console.log(this.tableData2);
      });
    },

    // 人员多选框方法
    handleSelectionChange1(val) {
      console.log(`788`, val);
      val.forEach((element) => {
        if (this.ryid.indexOf(element.id) == -1) {
          this.ryid.push(element.id);
        }
      });
    },
    // 设备多选框方法
    handleSelectionChange2(val) {
      console.log(`99`, val);
	  this.shebid.length = 0
      val.forEach((element) => {
        if (this.shebid.indexOf(element.id) == -1) {
          this.shebid.push(element.id);
        }
      });
	  console.log(this.shebid);
    },
	
    // 人员权限删除
    handelt() {
      let data = {
        method: "co.key.manage.person.del",
        id: this.ryid.toString(),
        manager_id: this.UserInfo.manager_id,
        person_id: this.authtabdiolgdata.id,
      };
      this.$serve(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: this.$t("devtable.sccg"),
              type: "success",
            });
          } else {
            this.$message({
              message: this.$t("devtable.scsb"),
              type: "error",
            });
          }
        })
        .then((res) => {
          this.handserveList();
        });
    },
    // 设备权限删除
    handsheb() {
      let data = {
        method: "co.key.manage.device.del",
        id: this.shebid.toString(),
        manager_id: this.UserInfo.manager_id,
        device_id: this.authtabdiolgdata.id,
      };
      this.$serve(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: this.$t("devtable.sccg"),
              type: "success",
            });
          } else {
            this.$message({
              message: this.$t("devtable.scsb"),
              type: "error",
            });
          }
        })
        .then((res) => {
          this.handequipmentList("");
        });
    },
	// 设备权限同步
	handeltTb(){
		let data = {
			method:'co.person.key.syn',
			id:this.shebid.toString(),
			agent_id:this.UserInfo.agent_id,
			manager_id:this.UserInfo.manager_id
		}
	},
    //人员权限查看列表 查询
    getsatList() {
      let data = {
        method: "co.key.check.person.list",
        page_index: 1,
        page_size: 1000,
        serial_number: this.serial_number,
        agent_id: this.UserInfo.agent_id,
        person_id: this.authtabdiolgdata.id,
        manager_id: this.UserInfo.manager_id,
        dept_id: "",
      };
      this.$serve(data).then((res) => {
        this.tableData = res.data.data;
      });
    },
    // 设备列表 ，查询
    handserveQuest() {
      this.handequipmentList(this.name);
    },
  },
};
</script>
<style lang="less" scoped>
.phonefp {
  margin-top: 65px;
  p {
    margin-top: 10px;
    color: red;
  }
}
</style>