<template>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagecount"
    >
    </el-pagination>
</template>
<script>
export default {
  // props:{
    // pagecount :Number
  // },
  data() {
    return {
      currentPage4: 4,
      pagecount:null
    };
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('handleSizeChange',val)
    },
    handleCurrentChange(val) {
      console.log(val)
      this.$emit('handleCurrentChange',val)
    },
  },
};
</script>